export class HomeScreenConfiguration {
    message: string
    rewardButtonText: string
    arcadeButtonText : string

    constructor (
        message: string,
        rewardButtonText: string,
        arcadeButtonText : string
    ) {
        this.message = message
        this.rewardButtonText = rewardButtonText
        this.arcadeButtonText = arcadeButtonText
    }
}
