import React from 'react'
import { AudioService } from '../delivery/services/AudioService'

const CustomButton = (props: {
    onClick: () => void,
    className: string,
    text: string,
    disabled: boolean,
    isBackButton : boolean,
    renderChildren : React.ReactNode[]
}) =>
    <button
        disabled={props.disabled}
        className={props.className}
        onClick={() => {
            props.onClick()
            props.isBackButton ? AudioService.playBack() : AudioService.playClick()
        }}>
        {props.text}
        {props.renderChildren}
    </button>

CustomButton.defaultProps = {
    onClick: () => { },
    className: '',
    text: '',
    disabled: false,
    isBackButton: false,
    renderChildren: []
}

export default CustomButton
