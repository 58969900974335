import amplitude, { AmplitudeClient } from 'amplitude-js'
import UAParser, { IResult } from 'ua-parser-js'
import packageJson from '../../../package.json'
import { UserProperties } from './properties/UserProperties'
import { IEventProperties } from '../interfaces/IEventProperties'
import { TrackMessage } from './TrackMessage'
import { BaseProperties } from './properties/BaseProperties'
import { RandomGenerator } from '../../domain/services/RandomGenerator'
import { HomeProperties } from './properties/HomeProperties'
import { ArcadeProperties } from './properties/ArcadeProperties'
import { ArcadeCompletedProperties } from './properties/ArcadeCompletedProperties'
import { TrackerConstants } from './TrackerConstants'
import { IServer } from '../interfaces/IServer'
import KeyValuePair from '../../domain/configurations/KeyValuePair'

export class TrackerService {
    private server: IServer
    private amplitude: AmplitudeClient
    private userProperties: UserProperties
    private userAgentInfo: IResult

    constructor (server: IServer, candidateCampaignId: string = '') {
        this.server = server
        this.amplitude = amplitude.getInstance()
        this.amplitude.init(' ')
        this.userProperties = new UserProperties(candidateCampaignId, 0, [])
        this.userAgentInfo = new UAParser(navigator.userAgent).getResult()
    }

    setProperties = (properties: KeyValuePair[]) => this.userProperties.properties = properties

    setProgress = (progress: number) => this.userProperties.progress = progress

    landingScreen = () => this.track(TrackerConstants.nav_landing)

    homeScreen = (props: HomeProperties) => this.track(TrackerConstants.nav_home, props)

    arcadeScreen = (props: ArcadeProperties) => this.track(TrackerConstants.nav_arcade, props)

    endCardScreen = () => this.track(TrackerConstants.nav_endcard)

    arcadeCompleted = (props: ArcadeCompletedProperties) => this.track(TrackerConstants.complete_arcade, props)

    private track (eventName: string, properties: IEventProperties = new BaseProperties()) {
        const message = new TrackMessage(
            this.amplitude.getUserId(),
            eventName,
            new Date().getTime(),
            { campaignId: this.userProperties.campaignId, ...properties },
            {
                progress: this.userProperties.progress,
                campaignId: this.userProperties.campaignId,
                ...Object.fromEntries(this.userProperties.properties.map(prop => { return [[prop.key], prop.value] }))
            },
            packageJson.version,
            this.amplitude.options.platform || '',
            this.userAgentInfo.os.name || '',
            this.userAgentInfo.os.version || '',
            this.amplitude.options.deviceId || '',
            this.userAgentInfo.device.model || '',
            this.userAgentInfo.device.vendor || '',
            'undefined',
            'undefined',
            'undefined',
            'undefined',
            this.amplitude.options.language || '',
            0,
            0,
            'undefined',
            this.amplitude.getSessionId(),
            RandomGenerator.guid()
        )

        console.debug(message)

        this.server.logEvent([message])
    }
}
