import { StorageConstants } from '../domain/constants/StorageConstants'
import KeyValuePair from '../domain/configurations/KeyValuePair'

export class LocalRepository {
    prefix: string

    constructor () {
        this.prefix = 'default'
    }

    getPrefix = () => localStorage.getItem('prefix') ?? 'no_prefix'

    setPrefix = (campaignId: string) => localStorage.setItem('prefix', campaignId)

    getFields = () => (this.get(StorageConstants.storage_fields) ?? '')
        .split(StorageConstants.storage_delimiter)
        .filter(field => field !== '')
        .map(field => JSON.parse(field) as KeyValuePair)

    setFields = (fields: KeyValuePair[]) => this.set(StorageConstants.storage_fields,
        fields.map(field => JSON.stringify(field)).join(StorageConstants.storage_delimiter)
    )

    getMuted = () => this.get(StorageConstants.storage_muted) === 'true'

    setMuted = (muted: boolean) => this.set(StorageConstants.storage_muted, muted.toString())

    getProgress = () => this.getPlayed().size ?? 0

    getPlayed = () => new Set((this.get(StorageConstants.storage_played) ?? '')
        .split(StorageConstants.storage_delimiter).filter(id => id !== ''))

    setPlayed = (played: Set<string>) => this.set(
        StorageConstants.storage_played,
        Array.from(played).join(StorageConstants.storage_delimiter)
    )

    getGiftClaimed = () => this.get(StorageConstants.storage_gift_claimed) === 'true'

    setGiftClaimed = () => this.set(StorageConstants.storage_gift_claimed, true.toString())

    addTrophyPlayed = (id: string) => this.setTrophyPlayed(this.getTrophyPlayed().add(id))

    getTrophyPlayed = () => new Set((this.get(StorageConstants.storage_trophy_played) ?? '')
        .split(StorageConstants.storage_delimiter).filter(id => id !== ''))

    setTrophyPlayed = (played: Set<string>) =>
        this.set(
            StorageConstants.storage_trophy_played,
            Array.from(played).join(StorageConstants.storage_delimiter)
        )

    private get = (key: string) =>
        localStorage.getItem(this.getPrefix() + '-' + key)

    private set = (key: string, value: any) =>
        localStorage.setItem(this.getPrefix() + '-' + key, value)
}
