import Field from './Field'

export class LandingConfiguration {
    title: string
    buttonText: string

    fields: Field[]

    constructor (title: string, buttonText: string, fields: Field[]) {
        this.title = title
        this.buttonText = buttonText
        this.fields = fields
    }
}
