import { BaseProperties } from './BaseProperties'

export class ArcadeProperties extends BaseProperties {
    name: string
    played: boolean

    constructor (name: string, played: boolean) {
        super()
        this.name = name
        this.played = played
    }
}
