import Field from './Field'

export class CampaignConfiguration {
    languages: string[]
    endcardUrl: string
    landingFields: Field[]

    constructor (languages: string[], endcardUrl: string, landingFields: Field[]) {
        this.languages = languages
        this.endcardUrl = endcardUrl
        this.landingFields = landingFields
    }
}
