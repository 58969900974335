import { BaseProperties } from './BaseProperties'

export class HomeProperties extends BaseProperties {
    origin: string

    constructor (origin: string) {
        super()
        this.origin = origin
    }
}
