import { IEventProperties } from '../interfaces/IEventProperties'

export class TrackMessage {
    user_id: string
    event_type: string
    time: number
    event_properties: IEventProperties
    user_properties: any
    app_version: string
    platform: string
    os_name: string
    os_version: string
    device_id: string
    device_model: string
    device_manufacturer: string
    country: string
    region: string
    city: string
    dma: string
    language: string
    location_lat: number
    location_lng: number
    ip: string
    session_id: number
    insert_id: string

    constructor (
        user_id: string,
        event_type: string,
        time: number,
        event_properties: IEventProperties,
        user_properties: any,
        app_version: string,
        platform: string,
        os_name: string,
        os_version: string,
        device_id: string,
        device_model: string,
        device_manufacturer: string,
        country: string,
        region: string,
        city: string,
        dma: string,
        language: string,
        location_lat: number,
        location_lng: number,
        ip: string,
        session_id: number,
        insert_id: string
    ) {
        this.user_id = user_id
        this.event_type = event_type
        this.time = time
        this.event_properties = event_properties
        this.user_properties = user_properties
        this.app_version = app_version
        this.platform = platform
        this.os_name = os_name
        this.os_version = os_version
        this.device_id = device_id
        this.device_model = device_model
        this.device_manufacturer = device_manufacturer
        this.country = country
        this.region = region
        this.city = city
        this.dma = dma
        this.language = language
        this.location_lat = location_lat
        this.location_lng = location_lng
        this.ip = ip
        this.session_id = session_id
        this.insert_id = insert_id
    }
}
