export class ArcadeInfo {
    link : string
    name : string
    played : boolean
    index : number

    constructor (link: string, name: string, alreadyPlayed: boolean, index : number) {
        this.link = link
        this.name = name
        this.played = alreadyPlayed
        this.index = index
    }
}
