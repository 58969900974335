import { Screens } from '../constants/Screens'

export class NavigationState {
    type: string
    link: string
    initialSlide : number

    constructor (type: string, link: string, initialSlide : number) {
        this.type = type
        this.link = link
        this.initialSlide = initialSlide
    }

    isPlaying = () => this.link !== ''

    isEndcard = () => this.type === Screens.endcard

    isHome = () => this.type === Screens.home
}
