import { LocalRepository } from '../infrastructure/LocalRepository'
import { Tracker } from './services/Tracker'
import { TrackerService } from '../infrastructure/tracker/TrackerService'
import { Server } from '../infrastructure/Server'
import { ArcadeInfo } from './entities/ArcadeInfo'
import { AudioService } from '../delivery/services/AudioService'
import { ConfigurationProvider } from './providers/ConfigurationProvider'
import { NavigationState } from './entities/NavigationState'
import { Screens } from './constants/Screens'
import { CampaignAssetsRepository } from '../infrastructure/CampaignAssetsRepository'
import { IServer } from '../infrastructure/interfaces/IServer'
import { CampaignConfiguration } from './configurations/CampaignConfiguration'
import KeyValuePair from './configurations/KeyValuePair'

export class AppPresenter {
    configurationProvider: ConfigurationProvider
    assetRepository: CampaignAssetsRepository
    tracker: Tracker
    arcadeInfo: ArcadeInfo
    localRepository: LocalRepository
    server: IServer

    setRegistered: (registered: boolean) => void

    setNavigationState: (state: NavigationState) => void

    setPlayTrophyAnimation: (play: boolean) => void
    setGiftActive: (play: boolean) => void
    setNavigateFromArcade: (play: boolean) => void
    setReady: (ready: boolean) => void
    selectedOptionLanguage: (option: string) => void

    constructor (
        candidateCampaignId: string | undefined,
        setRegistered: (registered: boolean) => void,
        setPlayTrophyAnimation: (play: boolean) => void,
        setGiftActive: (play: boolean) => void,
        setNavigateFromArcade: (play: boolean) => void,
        setNavigationState: (state: NavigationState) => void,
        setReady: (state: boolean) => void,
        selectedOptionLanguage: (option: string) => void
    ) {
        this.server = new Server()
        this.tracker = new Tracker(new TrackerService(this.server, candidateCampaignId))
        this.arcadeInfo = new ArcadeInfo(Screens.home, '', false, 0)
        this.localRepository = new LocalRepository()
        this.assetRepository = new CampaignAssetsRepository(candidateCampaignId)
        this.configurationProvider = new ConfigurationProvider(
            [''],
            [],
            new CampaignConfiguration([], '', [])
        )

        this.selectedOptionLanguage = selectedOptionLanguage
        this.setRegistered = setRegistered
        this.setPlayTrophyAnimation = setPlayTrophyAnimation
        this.setGiftActive = setGiftActive
        this.setNavigateFromArcade = setNavigateFromArcade
        this.setNavigationState = setNavigationState
        this.setReady = setReady
    }

    initialize = async () => {
        await this.assetRepository.initialize()
        this.localRepository.setPrefix(this.assetRepository.campaignId)

        const configuration = await this.getConfiguration(this.server, this.getCampaignId())
        if (!configuration.landingFields) {
            configuration.landingFields = []
        }
        const translations = await this.fetchTranslations(this.server, this.getCampaignId(), configuration.languages)

        console.log('the translation result is: ' + JSON.stringify(translations))
        const arcades = await this.fetchArcades(this.getCampaignId(), configuration.languages)
        this.configurationProvider = new ConfigurationProvider(translations, arcades, configuration)

        const fields = this.getLocalFields()
        this.tracker.setProperties(fields)
        this.tracker.setProgress(this.getLocalProgress())
        this.setTrackerInitialScreen()
        this.setGiftActive(this.allArcadesCompleted() && !this.getGiftClaimed())
        this.setRegistered(fields.length > 0)

        this.setReady(true)
    }

    private fetchArcades = async (campaignId: string, languages: string[]) => {
        const result:{[x: string]:{}} = {}

        for (const language of languages) {
            result[language] = await this.server.fetchArcades(campaignId, language)
        }
        return result
    }

    private fetchTranslations = async (server: IServer, campaignId: string, languages: string[]): Promise<{}> => {
        const result:{[x: string]:{}} = {}

        for (const language of languages) {
            result[language] = await server.fetchTranslations(campaignId, language).then(p => p.keys)
        }
        return result
    }

    private getConfiguration = async (server: IServer, campaignId: string) => await server.fetchConfiguration(campaignId)

    register = (fields: KeyValuePair[]) => {
        this.setLocalFields(fields)
        this.tracker.setProperties(fields)
        this.tracker.toHome()

        this.setRegistered(fields.length > 0)
    }

    play = (info: ArcadeInfo, progress: number) => {
        this.tracker.setProgress(progress)
        this.tracker.toArcade(info.name, info.played)

        this.arcadeInfo = info

        this.setNavigationState(new NavigationState(Screens.arcade, info.link, info.index))
    }

    back = () => {
        this.tracker.toHome()

        this.setNavigationState(new NavigationState(Screens.home, '', this.arcadeInfo.index))

        this.playTrophyFeedback()
        this.setGiftActive(this.allArcadesCompleted() && !this.getGiftClaimed())
        this.setNavigateFromArcade(true)
    }

    complete = () => {
        this.tracker.completeArcade(this.arcadeInfo.name)

        this.setNavigationState(new NavigationState(Screens.home, '', this.arcadeInfo.index))

        this.playTrophyFeedback()
        this.setGiftActive(this.allArcadesCompleted() && !this.getGiftClaimed())
        this.setNavigateFromArcade(true)
    }

    showEndCard = () => {
        this.tracker.toEndcard()

        this.setNavigationState(new NavigationState(Screens.endcard, '', this.arcadeInfo.index))

        this.setGiftClaimed()
        this.setGiftActive(false)
    }

    allArcadesCompleted = () =>
        this.localRepository.getPlayed().size === this.configurationProvider.getArcadeConfiguration().length

    getArcadeConfigurations = (language: string) => this.configurationProvider.getArcadeConfigurations(language)

    getLandingConfiguration = (language: string) => this.configurationProvider.getLandingConfiguration(language)

    getHomeScreenConfiguration = (language: string) => this.configurationProvider.getHomeScreenConfiguration(language)

    getEndCardScreenConfiguration = (language: string) => this.configurationProvider.getEndcardScreenConfiguration(language)

    getToolbarConfiguration = () => this.configurationProvider.getToolbarConfiguration()

    getLanguageConfiguration = () => this.configurationProvider.getLanguageConfiguration()

    setLanguageConfiguration = (option: string) => this.selectedOptionLanguage(option)

    playBGM = () => AudioService.playBgm()

    style = (file: string) => this.assetRepository.style(file)

    private playTrophyFeedback () {
        const id = this.getLastPlayedId()

        if (!this.hasAlreadyPlayed(id)) {
            this.setPlayTrophyAnimation(true)
            this.addTrophyPlayed(id)
            AudioService.playTrophy()
        }
    }

    private setTrackerInitialScreen = () => this.getLocalFields().length === 0
        ? this.tracker.toLanding()
        : this.tracker.toHome()

    private getLocalFields = () => this.localRepository.getFields()

    private setLocalFields = (fields: KeyValuePair[]) => this.localRepository.setFields(fields)

    private getLocalProgress = () => this.localRepository.getProgress()

    private getGiftClaimed = () => this.localRepository.getGiftClaimed()

    private setGiftClaimed = () => this.localRepository.setGiftClaimed()

    private getPlayed = () => Array.from(this.localRepository.getPlayed())

    private getLastPlayedId = () => {
        const played = this.getPlayed()
        return played.at(played.length - 1) !
    }

    private hasAlreadyPlayed = (playedId: string) => this.localRepository.getTrophyPlayed().has(playedId)

    private addTrophyPlayed = (playedId: string) => this.localRepository.addTrophyPlayed(playedId)

    private getCampaignId = () => this.localRepository.getPrefix()
}
