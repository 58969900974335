import React, { useState } from 'react'
import '../assets/css/Dropdown.css'
import { DropDownButtonProps } from './DeopDownButtonProps'

export const DropDownButton: React.FC<DropDownButtonProps> = ({ options, onSelect, defaultLanguage }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState<string | null>(null)

    const handleToggle = () => {
        setIsOpen(!isOpen)
    }

    const handleSelect = (option: string) => {
        options.unshift(options.splice(options.indexOf(option), 1)[0])
        setSelectedOption(option)
        onSelect(option)
        setIsOpen(false)
    }

    return (
        <div className="dropdown">
            {!isOpen && <button onClick={handleToggle} className="dropdown-button">
                {selectedOption || defaultLanguage}
            </button>
            }
            {isOpen && (
                <ul className="dropdown-menu">
                    {options.map((option, index) => (
                        <li key={index} onClick={() => {
                            handleSelect(option)
                        }} className="dropdown-item">
                            {option}
                        </li>
                    ))}
                </ul>
            )}
            <button onClick={handleToggle} className={isOpen ? 'dropdown-arrow-up' : 'dropdown-arrow'} ></button>
        </div>
    )
}

export default DropDownButton
