export default class Field {
    name: string
    hint: string
    label: string

    constructor (name: string, hint: string, label: string) {
        this.name = name
        this.hint = hint
        this.label = label
    }
}
