export class TranslationKeys {
    static readonly landing_title = 'login_head'
    static readonly landing_button = 'send'
    static readonly arcade_txt = 'login_head'
    static readonly arcade_play_button = 'play'
    static readonly back_button = 'back_button'
    static readonly prize_popup_button = 'end_button'
    static readonly win_popup_title = 'end_title'
    static readonly win_popup_txt_1 = 'end_text'
    static readonly win_popup_button = 'end_button'
    static readonly win_popup_txt_2 = 'end_thanks'
    static readonly email_hint = 'email'
    static readonly email_txt = 'login_text'
}
