import { BaseProperties } from './BaseProperties'

export class ArcadeCompletedProperties extends BaseProperties {
    name: string

    constructor (name: string) {
        super()
        this.name = name
    }
}
