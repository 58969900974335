import KeyValuePair from '../../../domain/configurations/KeyValuePair'

export class UserProperties {
    progress: number
    campaignId: string
    properties: KeyValuePair[]

    constructor (
        campaignId: string,
        progress: number,
        properties: KeyValuePair[]
    ) {
        this.campaignId = campaignId
        this.progress = progress
        this.properties = properties
    }
}
