import React from 'react'
import ReactDOM from 'react-dom/client'
import 'swiper/css'
import reportWebVitals from './reportWebVitals'
import App from './App'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <Router>
        <Routes>
            <Route path="/" element={<App/>}/>
            <Route path="/:campaignId" element={<App/>}/>
        </Routes>

    </Router>
)
reportWebVitals()
