import click from '../../assets/audio/click.mp3'
import back from '../../assets/audio/back.mp3'
import trophy from '../../assets/audio/trophy.mp3'
import background from '../../assets/audio/background.mp3'

export class AudioService {
    static isMuted = false
    static backgroundMusic = new Audio(background)

    static playBgm = () => {
        this.backgroundMusic.volume = 0.1
        this.backgroundMusic.loop = true
        this.backgroundMusic.play()
    }

    static playClick = () => {
        if (this.isMuted) return
        const audio = new Audio(click)
        audio.volume = 0.6
        return audio.play()
    }

    static playBack = () => {
        if (this.isMuted) return
        const audio = new Audio(back)
        audio.volume = 0.6
        return audio.play()
    }

    static playTrophy = () => {
        if (this.isMuted) return
        const audio = new Audio(trophy)
        audio.volume = 0.3
        return audio.play()
    }

    static setMuted = (muted: boolean) => {
        this.backgroundMusic.muted = muted
        this.isMuted = muted
    }
}
