import { TrackerService } from '../../infrastructure/tracker/TrackerService'
import { Screens } from '../constants/Screens'
import { TrackerProperties } from '../../infrastructure/tracker/TrackerProperties'
import KeyValuePair from '../configurations/KeyValuePair'

export class Tracker {
    private service: TrackerService
    private currentScreen: string

    constructor (tracker: TrackerService) {
        this.service = tracker
        this.currentScreen = ''
    }

    setProperties = (properties: KeyValuePair[]) => this.service.setProperties(properties)

    setProgress = (progress: number) => this.service.setProgress(progress)

    toLanding = () => {
        this.service.landingScreen()
        this.currentScreen = Screens.landing
    }

    toHome = () => {
        if (this.currentScreen === Screens.home) return
        this.service.homeScreen(TrackerProperties.homeFrom(this.currentScreen))
        this.currentScreen = Screens.home
    }

    completeArcade = (name: string) => {
        this.service.homeScreen(TrackerProperties.homeComplete())
        this.service.arcadeCompleted(TrackerProperties.arcadesCompleted(name))
        this.currentScreen = Screens.home
    }

    toArcade = (name: string, played: boolean) => {
        this.service.arcadeScreen(TrackerProperties.arcade(name, played))
        this.currentScreen = Screens.arcade
    }

    toEndcard = () => {
        this.service.endCardScreen()
        this.currentScreen = Screens.endcard
    }
}
