import { ArcadeConfiguration } from '../configurations/ArcadeConfiguration'
import { LandingConfiguration } from '../configurations/LandingConfiguration'
import { HomeScreenConfiguration } from '../configurations/HomeScreenConfiguration'
import { EndcardScreenConfiguration } from '../configurations/EndcardScreenConfiguration'
import { ToolbarConfiguration } from '../configurations/ToolbarConfiguration'
import { TranslationService } from '../services/TranslationService'
import { TranslationKeys } from '../services/TranslationKeys'
import { CampaignConfiguration } from '../configurations/CampaignConfiguration'
import Field from '../configurations/Field'

export class ConfigurationProvider {
    arcadeConfigurations: any
    translationService: TranslationService

    private configuration: CampaignConfiguration

    constructor (translations: {}, arcades: {}, configuration: CampaignConfiguration) {
        this.configuration = configuration
        this.translationService = new TranslationService(translations)
        this.arcadeConfigurations = arcades
    }

    getLandingConfiguration = (language:string) => {
        this.translationService.setLanguage(language)
        return new LandingConfiguration(
            this.translationService.translate(TranslationKeys.landing_title),
            this.translationService.translate(TranslationKeys.landing_button),
            [new Field(
                'email',
                this.translationService.translate(TranslationKeys.email_hint),
                this.translationService.translate(TranslationKeys.email_txt)
            )]

        )
    }

    getArcadeConfigurations = (language: string): ArcadeConfiguration[] => this.arcadeConfigurations[language]
    getArcadeConfiguration = (): ArcadeConfiguration[] => this.arcadeConfigurations[this.translationService.getLanguage()]

    getHomeScreenConfiguration = (language: string) => {
        this.translationService.setLanguage(language)
        return new HomeScreenConfiguration(
            this.translationService.translate(TranslationKeys.arcade_txt),
            this.translationService.translate(TranslationKeys.prize_popup_button),
            this.translationService.translate(TranslationKeys.arcade_play_button)
        )
    }

    getEndcardScreenConfiguration = (language:string) => {
        this.translationService.setLanguage(language)
        return new EndcardScreenConfiguration(
            this.translationService.translate(TranslationKeys.win_popup_title),
            this.translationService.translate(TranslationKeys.win_popup_txt_1),
            this.translationService.translate(TranslationKeys.win_popup_txt_2),
            this.translationService.translate(TranslationKeys.win_popup_button),
            this.configuration.endcardUrl
        )
    }

    getToolbarConfiguration = () => new ToolbarConfiguration(this.translationService.translate(TranslationKeys.back_button))

    getLanguageConfiguration = () => {
        console.log('languages = ' + this.configuration.languages)
        return this.configuration.languages
    }
}
