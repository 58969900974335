export class EndcardScreenConfiguration {
    title: string
    subtitle: string
    message: string
    buttonText: string
    endcardUrl: string

    constructor (title: string, subtitle: string, message: string, buttonText: string, endcardUrl : string) {
        this.title = title
        this.subtitle = subtitle
        this.message = message
        this.buttonText = buttonText
        this.endcardUrl = endcardUrl
    }
}
