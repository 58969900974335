export class TranslationService {
    private readonly map: any
    private language: string

    constructor (translations: {}) {
        this.language = 'en'
        this.map = translations
    }

    setLanguage = (language: string) : string =>
        this.language = language

    getLanguage = () : string => this.language

    translate = (key: string) : string =>
        this.map[this.language][key]
            ? this.map[this.language][key]
            : key.toUpperCase()

    translateMultiline = (key: string) : string[] =>
        this.map[this.language][key]
            ? this.map[this.language][key]
            : key.toUpperCase()
}
