import React from 'react'
import { GameScreen } from '../composable/GameScreen'
import { EndcardScreenProps } from '../props/EndcardScreenProps'
import CustomButton from '../CustomButton'

export const EndcardScreen = (props:EndcardScreenProps) =>
    <GameScreen>
        <link href={props.style} rel="stylesheet"/>
        <div className="logo" id="client-logo"/>

        <div className="container">
            <div className="ribbon-title">{props.configuration.title}</div>
            <div className="reward-circle-background">
                <div className="line description" id="endcard-subtitle">
                    <div>{props.configuration.subtitle}</div>
                </div>
                <div className="endcard-image"/>
            </div>
            <CustomButton
                className="wide-button endcard-button"
                text={props.configuration.buttonText}
                onClick={() => {
                    props.back()
                    open(props.configuration.endcardUrl)
                }}
            />
            <div className="line title brandon">{props.configuration.message}</div>
        </div>
    </GameScreen>
