import axios from 'axios'
import Environment from '../environment'

export class CampaignAssetsRepository {
    public campaignId: string

    private baseUrl = Environment.ASSET_REPOSITORY_LOCATION
    private fileToCheck = Environment.ASSETS_MAIN_FILE
    private readonly defaultCampaign = 'default'
    private readonly candidateCampaignId: string

    constructor (candidateCampaignId: string | undefined) {
        this.candidateCampaignId = candidateCampaignId ?? ''
        this.campaignId = this.defaultCampaign
    }

    initialize = async () => await this.checkCampaignAssets(this.candidateCampaignId)

    style = (name: String) => `${this.baseUrl}/${this.campaignId}/css/${name}`

    private checkCampaignAssets = (campaignId: string) =>
        axios.get(`${this.baseUrl}/${campaignId}/${this.fileToCheck}`,
            {
                headers: {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: '0'
                }
            })
            .then(() => this.campaignId = campaignId)
            .catch(() => console.log('invalid campaign id. Switching to default.'))
}
