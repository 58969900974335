import { HomeProperties } from './properties/HomeProperties'
import { ArcadeProperties } from './properties/ArcadeProperties'
import { ArcadeCompletedProperties } from './properties/ArcadeCompletedProperties'
import { TrackerConstants } from './TrackerConstants'

export class TrackerProperties {
    static homeComplete = () => new HomeProperties(TrackerConstants.arcade_complete)

    static homeFrom = (origin: string) => new HomeProperties(origin)

    static arcade = (name: string, played: boolean) => new ArcadeProperties(name, played)

    static arcadesCompleted = (name: string) => new ArcadeCompletedProperties(name)
}
