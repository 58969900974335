import axios, { AxiosInstance } from 'axios'
import { TrackMessage } from './tracker/TrackMessage'
import Environment from '../environment'
import { ArcadeConfiguration } from '../domain/configurations/ArcadeConfiguration'
import { IServer } from './interfaces/IServer'
import { CampaignConfiguration } from '../domain/configurations/CampaignConfiguration'

export class Server implements IServer {
    horusURL: string = ''
    assetRepositoryUrl:string = ''
    rocketUrl:string = ''
    logEventUrl: string = ''
    headers: {}

    axiosInstance: AxiosInstance
    private readonly project = 'eterland'

    constructor () {
        this.horusURL = Environment.HORUS_BACKEND_URL
        this.assetRepositoryUrl = Environment.ASSET_REPOSITORY_LOCATION
        this.rocketUrl = Environment.ROCKET_URL
        this.logEventUrl = this.horusURL + '/events'
        this.headers = { Source: this.project }

        this.axiosInstance = axios.create({
            timeout: 50000
        })
    }

    logEvent = (message: TrackMessage[]): Promise<void> => this.post(this.logEventUrl, message)

    fetchArcades = (campaignId: string, language: string): Promise<ArcadeConfiguration[]> =>
        this.get(`${this.assetRepositoryUrl}/${campaignId}/arcades/arcades_${language}.json`)
            .catch(_ => this.fetchDefaultArcades())

    fetchConfiguration = (campaignId: string): Promise<CampaignConfiguration> =>
        this.get(`${this.assetRepositoryUrl}/${campaignId}/configuration.json`)
            .catch(_ => this.fetchDefaultConfiguration())

    fetchTranslations = (campaignId: string, language: string): Promise<any> =>
        this.get(`${this.rocketUrl}/api/v1/groot/translations/${language}`)

    private fetchDefaultArcades = (): Promise<ArcadeConfiguration[]> =>
        this.get(`${this.assetRepositoryUrl}/default/arcades.json`)

    private fetchDefaultConfiguration = (): Promise<CampaignConfiguration> =>
        this.get(`${this.assetRepositoryUrl}/default/configuration.json`)

    private get = (url: string) =>
        this.axiosInstance.get(url)
            .then(response =>
                Promise.resolve(response.data)
            )
            .catch(error => {
                console.log(error)
                return Promise.reject(error)
            })

    private post = (url: string, body?: any) =>
        this.axiosInstance.post(
            url,
            body,
            { headers: this.headers }
        )
            .then(response =>
                Promise.resolve(response.data)
            )
            .catch(error => {
                console.log(error)
                return Promise.reject(error)
            })
}
