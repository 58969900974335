import React, { useEffect, useState } from 'react'
import { ArcadeScreenProps } from '../props/ArcadeScreenProps'
import { GameScreen } from '../composable/GameScreen'

export function ArcadeScreen (props: ArcadeScreenProps) {
    const arcadesOrigin = 'https://luna-labs-projects.s3.amazonaws.com'

    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        window.addEventListener('message', updateState, true)

        return () => window.removeEventListener('message', updateState, true)
    }, [])

    const updateState = (evt: MessageEvent<any>) => {
        if (evt.origin === arcadesOrigin) {
            props.back()
        }
    }

    const hideLoading = () => setLoading(false)

    return <GameScreen>
        <iframe onLoad={hideLoading} className="arcade-iframe" src={props.arcadeSource}/>
        <div className={isLoading ? 'loading-icon spin absolute-position' : 'hidden'}/>
    </GameScreen>
}
